/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  IBoton,
  ICategoria,
  ICategoriaCliente,
  ICliente,
  ICreateCliente,
  IListado,
  IQueryParam,
  TipoBoton,
  TipoBotonMobile,
  TipoCliente,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { PolygonDrawComponent } from '../../../auxiliares/polygon-draw/polygon-draw.component';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../auxiliares/servicios/listados.service';
import { ClientesService } from '../clientes.service';
import { TIPOS_CLIENTES } from '../../../../environments/contantes';

@Component({
  selector: 'app-crear-editar-cliente',
  templateUrl: './crear-editar-cliente.component.html',
  styleUrls: ['./crear-editar-cliente.component.scss'],
})
export class CrearEditarClienteComponent implements OnInit, OnDestroy {
  @ViewChild(PolygonDrawComponent) polygonDrawComponent:
    | PolygonDrawComponent
    | undefined;

  formulario?: UntypedFormGroup;
  loading = false;
  title?: string;

  public botonesMobile: TipoBotonMobile[] = [
    'Vacio',
    'Ayuda',
    'Alertas',
    'Alertas Grupo',
    'Cobertura',
    'Contactos',
    'Eventos',
    'Eventos Grupo',
    'Líneas de Colectivos',
    'Notificaciones',
    'Perfil',
    'Reclamos',
    'Tramites',
    'Turnos',
  ];

  public clientes: ICliente[] = [];
  public otrosClientes: google.maps.PolygonOptions[] = [];

  public categorias: ICategoria[] = [];
  public categoriasDisponibles: ICategoria[] = [];
  public tiposCliente?: TipoCliente[] = TIPOS_CLIENTES;
  public botones: IBoton[] = [];

  public id: string | null = null;
  public data?: ICliente;

  public acciones: TipoBoton[] = ['Reflector', 'Sirena', 'Alerta'];
  public boton1?: string;
  public boton2?: string;
  public boton3?: string;

  // Listado Continuo
  private datos$?: Subscription;
  private clientes$?: Subscription;
  private categorias$?: Subscription;
  private botones$?: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private service: ClientesService,
    private listadosService: ListadosService,
    private helper: HelperService,
    private route: ActivatedRoute,
  ) {}

  get imagenes(): UntypedFormGroup {
    return this.formulario?.get('imagenes') as UntypedFormGroup;
  }

  get configuracion(): UntypedFormGroup {
    return this.formulario?.get('configuracion') as UntypedFormGroup;
  }

  get categoriasDefault(): UntypedFormArray {
    return this.formulario?.get('categoriasDefault') as UntypedFormArray;
  }

  get categoriasCliente(): UntypedFormArray {
    return this.formulario?.get('categorias') as UntypedFormArray;
  }

  get sirenas(): boolean {
    return this.formulario?.get('sirenas')?.value;
  }

  get actualizaciones(): boolean {
    return this.configuracion.get('actualizacionesFirmware')?.value;
  }

  public addCategoriasCliente(categoria?: ICategoriaCliente): void {
    this.categoriasCliente.push(
      this.fb.group({
        idCategoria: [categoria?.idCategoria, Validators.required],
        imagenAbajo: [categoria?.imagenAbajo],
        imagenArriba: [categoria?.imagenArriba],
        imagenSirena: [categoria?.imagenSirena],
        imagenReflector: [categoria?.imagenReflector],
        imagenAdelante: [categoria?.imagenAdelante],
        imagenAtras: [categoria?.imagenAtras],
        imagenSirenaPush: [categoria?.imagenSirenaPush],
        imagenReflectorPush: [categoria?.imagenReflectorPush],
        imagenAdelantePush: [categoria?.imagenAdelantePush],
        imagenAtrasPush: [categoria?.imagenAtrasPush],
        imagenFondo: [categoria?.imagenFondo],
      }),
    );
  }

  public deleteCategoriasCliente(i: number): void {
    this.categoriasCliente.removeAt(i);
  }

  public addCategoriasDefaultCliente(categoria?: {
    desde: number;
    hasta: number;
    idCategoria: string;
  }): void {
    this.categoriasDefault.push(
      this.fb.group({
        desde: [categoria?.desde || null],
        hasta: [categoria?.hasta || null],
        idCategoria: [categoria?.idCategoria || null],
      }),
    );
  }

  public deleteCategoriasDefaultCliente(i: number): void {
    this.categoriasDefault.removeAt(i);
  }

  private crearFormulario(): void {
    this.formulario = this.fb.group({
      nombre: [this.data?.nombre, Validators.required],
      tipo: [this.data?.tipo, Validators.required],
      nombreAppMobile: [this.data?.nombreAppMobile],
      edadMinima: [
        this.data?.edadMinima,
        [Validators.min(1), Validators.max(21), Validators.required],
      ],
      twitter: [this.data?.twitter],
      sirenas: [this.data?.sirenas],
      porticos: [this.data?.porticos],
      configuracion: this.fb.group({
        direccion: [this.data?.configuracion?.direccion],
        hostSmartCity: [this.data?.configuracion?.hostSmartCity],
        // Monitoreo
        actualizacionesFirmware: [
          this.data?.configuracion?.actualizacionesFirmware,
        ],
        cronLimiteSirenasPor100: [
          this.data?.configuracion?.cronLimiteSirenasPor100,
          [Validators.min(1), Validators.max(100)],
        ],
        cronLimiteSirenasMax: [
          this.data?.configuracion?.cronLimiteSirenasMax,
          [Validators.min(1)],
        ],
        // Monitoreo
        verActualizaciones: [this.data?.configuracion?.verActualizaciones],
        nombreAppMonit: [this.data?.configuracion?.nombreAppMonit],
        // Boton
        colorInstalar: [this.data?.configuracion?.colorInstalar],
        colorMenu: [this.data?.configuracion?.colorMenu],
        colorMenuInferior: [this.data?.configuracion?.colorMenuInferior],
        mostrarMapa: [this.data?.configuracion?.mostrarMapa],
        mostrarSirenasOffline: [
          this.data?.configuracion?.mostrarSirenasOffline,
        ],
        mostrarEstadoSirenas: [this.data?.configuracion?.mostrarEstadoSirenas],
        // Sirenas
        distanciaCobertura: [this.data?.configuracion?.distanciaCobertura],
        verSirenasOnline: [this.data?.configuracion?.verSirenasOnline],
        // Contactos
        verContactos: [this.data?.configuracion?.verContactos],
        verReclamos: [this.data?.configuracion?.verReclamos],
        verEventos: [this.data?.configuracion?.verEventos],
        // Turnos
        verTurnos: [this.data?.configuracion?.verTurnos],
        verEstacionamientoMedido: [
          this.data?.configuracion?.verEstacionamientoMedido,
        ],
        verColectivos: [this.data?.configuracion?.verColectivos],
        notificacionesSirenas: [
          this.data?.configuracion?.notificacionesSirenas,
        ],
        // Evento Externo
        alertaEventoExterno: [this.data?.configuracion?.alertaEventoExterno],
        activarSirena: [this.data?.configuracion?.activarSirena],
        // Control RF
        boton1: [this.data?.configuracion?.boton1],
        boton2: [this.data?.configuracion?.boton2],
        boton3: [this.data?.configuracion?.boton3],
        // Botones mennu mobile
        boton1Mobile: [this.data?.configuracion?.boton1Mobile],
        boton2Mobile: [this.data?.configuracion?.boton2Mobile],
        boton3Mobile: [this.data?.configuracion?.boton3Mobile],
        // Telemedicina
        tieneTelemedicina: [this.data?.configuracion?.tieneTelemedicina],
      }),
      imagenes: this.fb.group({
        abajo: [this.data?.imagenes?.abajo],
        arriba: [this.data?.imagenes?.arriba],
        icono: [this.data?.imagenes?.icono],
        lateral: [this.data?.imagenes?.lateral],
        fondo: [this.data?.imagenes?.fondo],
        sirena: [this.data?.imagenes?.sirena],
        reflector: [this.data?.imagenes?.reflector],
        adelante: [this.data?.imagenes?.adelante],
        atras: [this.data?.imagenes?.atras],
        sirenaPush: [this.data?.imagenes?.sirenaPush],
        reflectorPush: [this.data?.imagenes?.reflectorPush],
        adelantePush: [this.data?.imagenes?.adelantePush],
        atrasPush: [this.data?.imagenes?.atrasPush],
      }),
      categorias: this.fb.array([], Validators.required),
      idCategoriaDefault: [this.data?.idCategoriaDefault, Validators.required],
      categoriasDefault: this.fb.array([]),
    });
    if (this.data?.categorias) {
      for (const categoria of this.data.categorias) {
        this.addCategoriasCliente(categoria);
      }
    } else {
      const categoriaDefault = this.categorias?.find(
        (categoria) => categoria.nombre === 'Normal',
      );
      if (categoriaDefault) {
        const categoriaClienteDefault: ICategoriaCliente = {
          idCategoria: categoriaDefault._id,
        };
        this.addCategoriasCliente(categoriaClienteDefault);
      }
    }

    if (this.data?.categoriasDefault) {
      for (const categoria of this.data.categoriasDefault) {
        this.addCategoriasDefaultCliente(categoria);
      }
    }
  }

  public async selectDireccion(direccion: string) {
    this.configuracion.patchValue({ direccion });
  }

  public mostrarCategoria(categoria: ICategoria, i: number): boolean {
    for (let j = 0; j < this.categoriasCliente.controls.length; j++) {
      const idSeleccionado =
        this.categoriasCliente.controls[j].get('idCategoria')?.value;
      if (idSeleccionado === categoria._id) {
        if (i === j) {
          return true;
        }
        return false;
      }
    }
    return true;
  }

  public mostrarCategoriaDefault(categoria: ICategoria): boolean {
    // tslint:disable-next-line: prefer-for-of
    for (let j = 0; j < this.categoriasCliente.controls.length; j++) {
      const idSeleccionado =
        this.categoriasCliente.controls[j].get('idCategoria')?.value;
      if (idSeleccionado === categoria._id) {
        return true;
      }
    }
    return false;
  }

  public volver(): void {
    window.history.back();
  }

  //

  private getData(): ICreateCliente {
    const data: ICreateCliente = this.formulario?.value;
    data.coordenadas = this.polygonDrawComponent?.polygons || [];
    data.superficie = this.helper.calcularAreas(data.coordenadas);
    return data;
  }

  public updateBoton(number: number, value: string) {
    if (value === 'Sirena' || value === 'Reflector') {
      if (number === 1) {
        this.boton1 = value;
        this.configuracion.patchValue({ boton1: value });
      }
      if (number === 2) {
        this.boton2 = value;
        this.configuracion.patchValue({ boton2: value });
      }
      if (number === 3) {
        this.boton3 = value;
        this.configuracion.patchValue({ boton3: value });
      }
    } else {
      if (number === 1) {
        this.boton1 = value;
        this.configuracion.patchValue({ boton1: '' });
      }
      if (number === 2) {
        this.boton2 = value;
        this.configuracion.patchValue({ boton2: '' });
      }
      if (number === 3) {
        this.boton3 = value;
        this.configuracion.patchValue({ boton3: '' });
      }
    }
  }

  public cargarConfigControles() {
    if (!this.id) return;
    const bot1 = this.configuracion.get('boton1')?.value;

    if (bot1 === 'Reflector' || bot1 === 'Sirena') {
      this.boton1 = bot1;
    } else if (bot1 === null || bot1 === undefined) {
      this.boton1 = undefined;
    } else {
      this.boton1 = 'Alerta';
    }

    const bot2 = this.configuracion.get('boton2')?.value;
    if (bot2 === 'Reflector' || bot2 === 'Sirena') {
      this.boton2 = bot2;
    } else if (bot2 === null || bot2 === undefined) {
      this.boton2 = undefined;
    } else {
      this.boton2 = 'Alerta';
    }

    const bot3 = this.configuracion.get('boton3')?.value;
    if (bot3 === 'Reflector' || bot3 === 'Sirena') {
      this.boton3 = bot3;
    } else if (bot3 === null || bot3 === undefined) {
      this.boton3 = undefined;
    } else {
      this.boton3 = 'Alerta';
    }
  }

  public onColorChange(event: string, control: string) {
    this.configuracion?.get(control)?.patchValue(event);
  }

  public async enviar(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      if (this.data?._id) {
        await firstValueFrom(this.service.editar(this.data._id, data));
        this.helper.notifSuccess('Editado correctamente');
      } else {
        await firstValueFrom(this.service.crear(data));
        this.helper.notifSuccess('Creado correctamente');
      }
      this.volver();
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  // Listados

  private async listar(id?: string): Promise<void> {
    if (id) {
      this.datos$?.unsubscribe();
      this.datos$ = this.listadosService
        .subscribe<ICliente>('cliente', id)
        .subscribe((data) => {
          this.data = data;
          console.debug(
            new Date().toLocaleString(),
            `listado de cliente`,
            data,
          );
        });
      await this.listadosService.getLastValue('cliente', id);
    }
  }

  private async listarClientes(): Promise<void> {
    // Filter
    const query: IQueryParam = {
      select: 'coordenadas',
    };

    // Query
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listadosService
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        this.otrosClientes = [];
        for (const cliente of this.clientes) {
          if (cliente._id !== this.id) {
            this.otrosClientes.push({
              clickable: false,
              fillColor: 'green',
              strokeColor: 'green',
              paths: cliente.coordenadas,
            });
          }
        }
        console.debug(new Date().toLocaleString(), `listado de clientes`, data);
      });
    await this.listadosService.getLastValue('clientes', query);
  }

  private async listarCategorias(): Promise<void> {
    // Filter
    const query: IQueryParam = {
      sort: 'nombre',
      select: 'nombre variante',
    };

    // Query
    this.categorias$?.unsubscribe();
    this.categorias$ = this.listadosService
      .subscribe<IListado<ICategoria>>('categorias', query)
      .subscribe((data) => {
        this.categorias = data.datos;
        console.debug(
          new Date().toLocaleString(),
          `listado de categorias`,
          data,
        );
      });
    await this.listadosService.getLastValue('categorias', query);
  }

  private async listarBotones(idCliente: string): Promise<void> {
    // Query
    this.botones$?.unsubscribe();
    this.botones$ = this.listadosService
      .subscribe<IListado<IBoton>>('botonesPorCliente', idCliente)
      .subscribe((data) => {
        this.botones = data.datos;
        console.debug(
          new Date().toLocaleString(),
          `listado de botonesPorCliente`,
          data,
        );
      });
    await this.listadosService.getLastValue('botonesPorCliente', idCliente);
  }

  public async setFile(
    event: Event,
    formControlName: string,
    idCategoria?: string,
    index?: number,
  ): Promise<void> {
    this.loading = true;
    const file = (event as any).target?.files?.item(0);
    const nombreCliente = this.formulario?.get('nombre')?.value;
    const categoria = this.categorias?.find((c) => {
      return c._id === idCategoria;
    });
    const res = await firstValueFrom(
      this.service.subirImagen(
        file,
        nombreCliente,
        formControlName,
        categoria?.nombre,
      ),
    );

    const val = res.url + `?${(Math.random() * 1000).toFixed(0)}`;
    if (index || index === 0) {
      const control = this.categoriasCliente.at(index);
      control.patchValue({ [formControlName]: val });
    } else {
      const control = this.imagenes;
      control.patchValue({ [formControlName]: val });
    }
    this.loading = false;
  }

  public nombresValido(): boolean {
    const nombre = this.formulario?.get('nombre')?.value;
    if (nombre) {
      return true;
    } else {
      return false;
    }
  }

  private required(): void {
    this.configuracion
      .get('actualizacionesFirmware')
      ?.valueChanges.subscribe((val) => {
        if (val) {
          this.configuracion.controls.cronLimiteSirenasPor100.setValidators([
            Validators.required,
          ]);
          this.configuracion.controls.cronLimiteSirenasMax.setValidators([
            Validators.required,
          ]);
        } else {
          this.configuracion.controls.cronLimiteSirenasPor100.clearValidators();
          this.configuracion.controls.cronLimiteSirenasMax.clearValidators();
        }
        this.configuracion.controls.cronLimiteSirenasPor100.updateValueAndValidity();
        this.configuracion.controls.cronLimiteSirenasMax.updateValueAndValidity();
      });
  }

  //

  async ngOnInit(): Promise<void> {
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    this.title = this.id ? 'Editar' : 'Crear';
    if (this.id) {
      await Promise.all([this.listar(this.id), this.listarBotones(this.id)]);
    }
    this.crearFormulario();
    this.cargarConfigControles();
    await Promise.all([this.listarClientes(), this.listarCategorias()]);
    console.log(this.data);
    this.required();
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
    this.clientes$?.unsubscribe();
    this.categorias$?.unsubscribe();
    this.botones$?.unsubscribe();
  }
}
