<mat-progress-bar
  mode="indeterminate"
  color="warn"
  [style.display]="loading ? 'block' : 'none'"
></mat-progress-bar>

<h2 class="m-4 nowrap">
  <span class="titulo">
    <span class="cursor-pointer" (click)="volver()"> Pórticos</span>
    >
    {{ title }}
  </span>
</h2>

<div class="table-container mat-elevation-z8 p-2">
  <form
    id="form"
    *ngIf="formulario"
    [formGroup]="formulario"
    autocomplete="off"
  >
    <div
      fxLayout.lt-md="column"
      fxLayout="row"
      fxLayoutGap.gt-sm="1em"
      fxLayoutAlign="start center"
    >
      <mat-form-field>
        <mat-label>Nombre del Pórtico</mat-label>
        <input matInput formControlName="nombre" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Fecha de Colocación</mat-label>
        <input
          matInput
          [matDatepicker]="picker1"
          formControlName="fechaColocacion"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Cliente</mat-label>
        <mat-select formControlName="idCliente">
          <mat-option *ngFor="let c of clientes" [value]="c._id">
            {{ c.nombre }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Marca de Cámara</mat-label>
        <input matInput formControlName="marcaCamara" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Modelo de Cámara</mat-label>
        <input matInput formControlName="modeloCamara" />
      </mat-form-field>
    </div>

    <!-- Mapa -->
    <div
      *ngIf="coordenadas"
      style="margin: 0 auto; width: 60%; height: 20%"
      fxLayoutAlign="center center"
    >
      <div
        [style.padding]="'0 1em'"
        fxLayout="column"
        fxLayoutGap="15px"
        fxFlex
      >
        <google-map width="100%" [center]="coordenadas" [options]="mapOptions">
          <map-marker
            [options]="markerOptions"
            [position]="coordenadas"
            (mapDragend)="setLocation($event)"
          ></map-marker>
        </google-map>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div
      fxLayout.lt-md="column"
      fxLayout="row"
      fxLayoutGap.gt-sm="2em"
      fxLayoutAlign="start center"
    >
      <!-- Porticos -->
      <mat-label>Configuración</mat-label>
    </div>
    <!-- Imagen -->
    <div style="margin: 1em">
      <div
        fxLayout.lt-md="column"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap.gt-sm="15px"
      >
        <mat-card-content>
          <mat-form-field>
            <mat-label>Cantidad de cámaras</mat-label>
            <mat-select formControlName="cantCamaras">
              <mat-option *ngFor="let numero of numeros" [value]="numero">
                {{ numero }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-card-content>

        <mat-form-field style="width: 60%">
          <mat-label>Tamaño imagen fondo</mat-label>
          <input matInput formControlName="tamFondo" />
        </mat-form-field>

        <!-- URL -->
        <mat-form-field>
          <mat-label>URL de la imagen de fondo</mat-label>
          <input matInput formControlName="imgFondo" />
        </mat-form-field>

        <!-- Muestra de la Imagen -->
        <img
          [src]="formulario.get('imgFondo')?.value"
          alt="Sin Imagen"
          height="100"
        />
      </div>
    </div>

    <div style="position: relative">
      <div class="subgrupo" formArrayName="configuraciones">
        <!-- <div class="subgrupo" [formGroup]="formularioConfig"> -->
        <div
          *ngFor="let numero of getPatentes(); let i = index"
          [formGroupName]="i"
        >
          <div class="subgrupo">
            <div
              fxLayout.lt-md="column"
              fxLayout="row"
              fxLayoutGap.gt-sm="2em"
              fxLayoutAlign="start center"
            >
              <!-- Porticos -->
              <mat-label>Configuración cámara {{ numero }} </mat-label>
            </div>
            <div
              fxLayout.lt-md="column"
              fxLayout="row"
              fxLayoutGap.gt-sm="1em"
              fxLayoutAlign="start center"
            >
              <mat-divider></mat-divider>
              <mat-form-field>
                <mat-label>Número Cámara</mat-label>
                <input
                  matInput
                  formControlName="numeroCamara"
                  type="text"
                  pattern="^[0-9]*$"
                />
                <mat-error
                  *ngIf="
                    configuraciones
                      .at(i)
                      .get('numeroCamara')
                      ?.hasError('required') &&
                    configuraciones.at(i).get('numeroCamara')?.touched
                  "
                >
                  Campo Requerido
                </mat-error>
              </mat-form-field>

              <mat-form-field>
                <mat-label>Tamaño de la Patente</mat-label>
                <input
                  matInput
                  formControlName="tamP"
                  type="text"
                  pattern="^[0-9]*$"
                />
                <mat-error
                  *ngIf="
                    configuraciones.at(i).get('tamP')?.hasError('required') &&
                    configuraciones.at(i).get('tamP')?.touched
                  "
                >
                  Campo Requerido
                </mat-error>
              </mat-form-field>

              <mat-form-field>
                <mat-label>Distancia Vertical de la Patente</mat-label>
                <input
                  matInput
                  formControlName="disSupP"
                  type="text"
                  pattern="^[0-9]*$"
                />
                <mat-error
                  *ngIf="
                    configuraciones
                      .at(i)
                      .get('disSupP')
                      ?.hasError('required') &&
                    configuraciones.at(i).get('disSupP')?.touched
                  "
                >
                  Campo Requerido
                </mat-error>
              </mat-form-field>

              <mat-form-field>
                <mat-label>Distancia Horizontal de la Patente</mat-label>
                <input
                  matInput
                  formControlName="disIzqP"
                  type="text"
                  pattern="^[0-9]*$"
                />
                <mat-error
                  *ngIf="
                    configuraciones
                      .at(i)
                      .get('disIzqP')
                      ?.hasError('required') &&
                    configuraciones.at(i).get('disIzqP')?.touched
                  "
                >
                  Campo Requerido
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      fxLayout.lt-md="column"
      fxLayout="row"
      fxLayoutGap.gt-sm="2em"
      fxLayoutAlign="start center"
    >
      <div fxLayoutGap="10px" style="text-align: end">
        <button
          class="porticoSpinner"
          mat-raised-button
          color="primary"
          type="submit"
          form="form"
          (click)="enviar()"
        >
          <div
            class="loadSpinnerButton"
            [style.display]="loading ? 'flex' : 'none'"
          >
            <mat-spinner
              class="mx-2"
              [diameter]="25"
              mode="indeterminate"
              color="warn"
            ></mat-spinner>
          </div>
          <div [style.display]="loading ? 'none' : 'flex'">Guardar</div>
        </button>
        <button mat-raised-button type="button" (click)="volver()">
          Volver
        </button>
      </div>
    </div>
  </form>
</div>
