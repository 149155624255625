<mat-progress-bar
  mode="indeterminate"
  color="warn"
  [style.display]="loading ? 'block' : 'none'"
></mat-progress-bar>

<h2 class="m-4 nowrap">
  <span class="titulo">
    <span class="cursor-pointer" (click)="volver()">Clientes</span>
    >
    {{ title }}
  </span>
</h2>

<form
  id="form"
  *ngIf="formulario"
  [formGroup]="formulario"
  (ngSubmit)="enviar()"
  autocomplete="off"
>
  <div class="m-2">
    <mat-accordion>
      <!-- Basico -->
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title> Básico </mat-panel-title>
          <mat-panel-description>
            Configuraciones básicas
          </mat-panel-description>
        </mat-expansion-panel-header>
        <!-- Contenido -->
        <ng-container>
          <!-- Nombre -->
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input
              matInput
              placeholder="Nombre del cliente"
              formControlName="nombre"
              required
            />
          </mat-form-field>

          <!-- Direccion -->
          <app-autocomplete-direccion
            [direccion]="data?.configuracion?.direccion || ''"
            (direccionChange)="selectDireccion($event)"
            style="width: 100%"
          ></app-autocomplete-direccion>

          <!-- Tipo -->
          <mat-form-field>
            <mat-label>Tipo</mat-label>
            <mat-select formControlName="tipo" required>
              <ng-container *ngFor="let dato of tiposCliente">
                <mat-option [value]="dato">
                  {{ dato }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <!-- Nombre -->
          <mat-form-field>
            <mat-label>Nombre APP Mobile</mat-label>
            <input
              matInput
              placeholder="Nombre de la aplicación mobile"
              formControlName="nombreAppMobile"
            />
          </mat-form-field>

          <!-- Edad Minima -->
          <mat-form-field>
            <mat-label>Edad Mínima</mat-label>
            <input
              type="number"
              matInput
              placeholder="Edad mínima requerida para registro de vecinos"
              formControlName="edadMinima"
            />
            <mat-error
              *ngIf="formulario.controls['edadMinima'].hasError('required')"
            >
              Este campo es obligatorio.</mat-error
            >
            <mat-error
              *ngIf="formulario.controls['edadMinima'].hasError('min')"
            >
              El valor debe ser mayor a 1.</mat-error
            >
            <mat-error
              *ngIf="formulario.controls['edadMinima'].hasError('max')"
            >
              El valor debe ser menor a 21.</mat-error
            >
          </mat-form-field>

          <!-- Twitter -->
          <mat-form-field>
            <mat-label>Twitter</mat-label>
            <input
              matInput
              placeholder="@municipio"
              formControlName="twitter"
            />
          </mat-form-field>

          <!-- Host Smart City -->
          <mat-form-field [formGroup]="configuracion">
            <mat-label>Host Smart City</mat-label>
            <input
              matInput
              placeholder="Host Smart City"
              formControlName="hostSmartCity"
            />
          </mat-form-field>
        </ng-container>
      </mat-expansion-panel>

      <!-- Sirenas -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Sirenas </mat-panel-title>
          <mat-panel-description>
            Configuraciones para las sirenas del cliente
          </mat-panel-description>
        </mat-expansion-panel-header>
        <!-- Contenido -->
        <ng-container>
          <!-- Sirenas -->
          <div class="mb-3">
            <mat-checkbox formControlName="sirenas">Tiene Sirenas</mat-checkbox>
          </div>
          <!-- Cobertura -->
          <ng-container [formGroup]="configuracion" *ngIf="sirenas">
            <mat-form-field *ngIf="sirenas" style="margin-bottom: 20px">
              <mat-label> Distancia de Cobertura de las Sirenas </mat-label>
              <input
                matInput
                placeholder="100"
                formControlName="distanciaCobertura"
                type="number"
              />
              <mat-hint>En metros</mat-hint>
            </mat-form-field>
          </ng-container>

          <!-- Actualizacion automatica firmware sirenas -->
          <ng-container [formGroup]="configuracion">
            <mat-checkbox
              *ngIf="sirenas"
              formControlName="actualizacionesFirmware"
              style="margin-bottom: 1em"
            >
              Actualización Automática de Firmware de Sirenas
            </mat-checkbox>

            <mat-form-field *ngIf="sirenas && actualizaciones">
              <mat-label>
                Porcentaje máximo de sirenas que se pueden actualizar
                simultáneamente
              </mat-label>
              <input
                matInput
                placeholder="Entre 1% y 100%"
                formControlName="cronLimiteSirenasPor100"
                type="number"
              />
            </mat-form-field>
            <mat-form-field *ngIf="sirenas && actualizaciones">
              <mat-label>
                Número (absoluto) máximo de sirenas que se pueden actualizar
                simultáneamente
              </mat-label>
              <input
                matInput
                placeholder="Mínimo 1"
                formControlName="cronLimiteSirenasMax"
                type="number"
              />
            </mat-form-field>
          </ng-container>
        </ng-container>
      </mat-expansion-panel>

      <!-- Porticos -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Porticos </mat-panel-title>
          <mat-panel-description>
            Configuraciones para los porticos del cliente
          </mat-panel-description>
        </mat-expansion-panel-header>
        <!-- Contenido -->
        <ng-container>
          <!-- Porticos -->
          <div class="mb-3">
            <mat-checkbox formControlName="porticos"
              >Tiene Porticos</mat-checkbox
            >
          </div>
        </ng-container>
      </mat-expansion-panel>

      <!-- Telemedicina -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Telemedicina </mat-panel-title>
          <mat-panel-description>
            Configuracion Telemedicina
          </mat-panel-description>
        </mat-expansion-panel-header>
        <!-- Contenido -->
        <ng-container [formGroup]="configuracion">
          <!-- Telemedicina -->
          <div class="mb-3">
            <mat-checkbox formControlName="tieneTelemedicina"
              >Tiene Telemedicina</mat-checkbox
            >
          </div>
        </ng-container>
      </mat-expansion-panel>

      <!-- Imagenes -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Imágenes </mat-panel-title>
          <mat-panel-description> Imágenes del cliente </mat-panel-description>
        </mat-expansion-panel-header>
        <!-- Contenido -->
        <ng-container>
          <!-- Imagenes -->
          <div class="subgrupo" [formGroup]="imagenes">
            <h2>Imágenes</h2>
            <div
              fxLayout="row wrap"
              fxLayoutAlign="start center"
              fxLayoutGap="15px"
            >
              <!-- FONDO -->
              <app-upload-image
                [disabled]="!nombresValido()"
                [formulario]="formulario"
                [formGroup]="imagenes"
                [controlName]="'fondo'"
                [label]="'Fondo'"
              ></app-upload-image>

              <!-- ICONO -->
              <app-upload-image
                [disabled]="!nombresValido()"
                [formulario]="formulario"
                [formGroup]="imagenes"
                [controlName]="'icono'"
                [label]="'Icono'"
              ></app-upload-image>

              <!-- LATERAL -->
              <app-upload-image
                [disabled]="!nombresValido()"
                [formulario]="formulario"
                [formGroup]="imagenes"
                [controlName]="'lateral'"
                [label]="'Lateral'"
              ></app-upload-image>

              <!-- ARRIBA -->
              <app-upload-image
                [disabled]="!nombresValido()"
                [formulario]="formulario"
                [formGroup]="imagenes"
                [controlName]="'arriba'"
                [label]="'Arriba'"
              ></app-upload-image>

              <!-- ABAJO -->
              <app-upload-image
                [disabled]="!nombresValido()"
                [formulario]="formulario"
                [formGroup]="imagenes"
                [controlName]="'abajo'"
                [label]="'Abajo'"
              ></app-upload-image>

              <!-- Sirena -->
              <app-upload-image
                [disabled]="!nombresValido()"
                [formulario]="formulario"
                [formGroup]="imagenes"
                [controlName]="'sirena'"
                [label]="'Sirena'"
              ></app-upload-image>

              <!-- Sirena Push-->
              <app-upload-image
                [disabled]="!nombresValido()"
                [formulario]="formulario"
                [formGroup]="imagenes"
                [controlName]="'sirenaPush'"
                [label]="'Sirena Push'"
              ></app-upload-image>

              <!-- Reflector -->
              <app-upload-image
                [disabled]="!nombresValido()"
                [formulario]="formulario"
                [formGroup]="imagenes"
                [controlName]="'reflector'"
                [label]="'Reflector'"
              ></app-upload-image>

              <!-- Reflector Push -->
              <app-upload-image
                [disabled]="!nombresValido()"
                [formulario]="formulario"
                [formGroup]="imagenes"
                [controlName]="'reflectorPush'"
                [label]="'Reflector Push'"
              ></app-upload-image>

              <!-- Adelante -->
              <app-upload-image
                [disabled]="!nombresValido()"
                [formulario]="formulario"
                [formGroup]="imagenes"
                [controlName]="'adelante'"
                [label]="'Adelante'"
              ></app-upload-image>

              <!-- Adelante Push -->
              <app-upload-image
                [disabled]="!nombresValido()"
                [formulario]="formulario"
                [formGroup]="imagenes"
                [controlName]="'adelantePush'"
                [label]="'Adelante Push'"
              ></app-upload-image>

              <!-- Atrás -->
              <app-upload-image
                [disabled]="!nombresValido()"
                [formulario]="formulario"
                [formGroup]="imagenes"
                [controlName]="'atras'"
                [label]="'Atras'"
              ></app-upload-image>

              <!-- Atrás Push -->
              <app-upload-image
                [disabled]="!nombresValido()"
                [formulario]="formulario"
                [formGroup]="imagenes"
                [controlName]="'atrasPush'"
                [label]="'AtrasPush'"
              ></app-upload-image>
            </div>
          </div>
        </ng-container>
      </mat-expansion-panel>

      <!-- Categorias -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Categorías </mat-panel-title>
          <mat-panel-description>
            Configuraciones para las categorías del cliente
          </mat-panel-description>
        </mat-expansion-panel-header>
        <!-- Contenido -->
        <ng-container>
          <!-- Categorias -->
          <div formArrayName="categorias" style="margin-bottom: 2em">
            <h2>Categorías Disponibles</h2>

            <div
              class="subgrupo"
              *ngFor="let alias of categoriasCliente.controls; let i = index"
            >
              <div [formGroupName]="i">
                <!-- CATEGORIA -->
                <ng-select
                  formControlName="idCategoria"
                  placeholder="Categoría"
                  bindValue="_id"
                  bindLabel="nombre"
                  appendTo="body"
                >
                  @for (item of categorias; track $index) {
                    @if (mostrarCategoria(item, i)) {
                      <ng-option [value]="item._id">
                        {{ item.nombre }}
                        {{ item.variante ? "(" + item.variante + ")" : "" }}
                      </ng-option>
                    }
                  }
                </ng-select>

                <!-- Imagenes -->
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title> Imágenes </mat-panel-title>
                    <mat-panel-description>
                      Configuraciones de imagenes de la categoría
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div
                    fxLayout="row wrap"
                    fxLayoutGap="15px"
                    class="boton-imagenes"
                  >
                    <!-- FONDO -->
                    <div
                      class="mb-3"
                      fxLayout="row"
                      fxLayoutAlign="center center"
                      fxLayoutGap="15px"
                      fxFlex="100"
                    >
                      <button
                        mat-raised-button
                        type="button"
                        class="mb-3"
                        color="primary"
                        (click)="fileInputCategoriaFondo.click()"
                        [disabled]="!nombresValido()"
                      >
                        <mat-icon>upload</mat-icon>
                        <span>Fondo</span>
                      </button>
                      <input
                        #fileInputCategoriaFondo
                        matInput
                        type="file"
                        style="position: fixed; top: -20000px"
                        accept="image/*"
                        (change)="
                          setFile(
                            $event,
                            'imagenFondo',
                            alias?.get('idCategoria')?.value,
                            i
                          )
                        "
                      />
                      <mat-form-field fxFlex>
                        <mat-label>URL Imagen Fondo</mat-label>
                        <input
                          #urlInputImagenFondo
                          matInput
                          formControlName="imagenFondo"
                        />
                      </mat-form-field>

                      <img
                        [src]="
                          categoriasCliente.controls[i].get('imagenFondo')
                            ?.value
                        "
                        alt="Sin Imagen"
                        [style.max-width]="'100px'"
                      />
                    </div>
                    <!-- ARRIBA -->
                    <div
                      class="mb-3"
                      fxLayout="row"
                      fxLayoutAlign="center center"
                      fxLayoutGap="15px"
                      fxFlex="48"
                    >
                      <button
                        mat-raised-button
                        type="button"
                        class="mb-3"
                        color="primary"
                        (click)="fileInputCategoriaArriba.click()"
                        [disabled]="!nombresValido()"
                      >
                        <mat-icon>upload</mat-icon>
                        <span>Superior</span>
                      </button>
                      <input
                        #fileInputCategoriaArriba
                        matInput
                        type="file"
                        style="position: fixed; top: -20000px"
                        accept="image/*"
                        (change)="
                          setFile(
                            $event,
                            'imagenArriba',
                            alias?.get('idCategoria')?.value,
                            i
                          )
                        "
                      />
                      <mat-form-field fxFlex>
                        <mat-label>URL Imagen Superior</mat-label>
                        <input
                          #urlInputImagenArriba
                          matInput
                          formControlName="imagenArriba"
                        />
                      </mat-form-field>

                      <img
                        [src]="
                          categoriasCliente.controls[i].get('imagenArriba')
                            ?.value
                        "
                        alt="Sin Imagen"
                        [style.max-width]="'100px'"
                      />
                    </div>
                    <!-- ABAJO -->
                    <div
                      class="mb-3"
                      fxLayout="row"
                      fxLayoutAlign="center center"
                      fxLayoutGap="15px"
                      fxFlex="48"
                    >
                      <button
                        mat-raised-button
                        type="button"
                        class="mb-3"
                        color="primary"
                        (click)="fileInputCategoriaAbajo.click()"
                        [disabled]="!nombresValido()"
                      >
                        <mat-icon>upload</mat-icon>
                        <span>Inferior</span>
                      </button>
                      <input
                        #fileInputCategoriaAbajo
                        matInput
                        type="file"
                        style="position: fixed; top: -20000px"
                        accept="image/*"
                        (change)="
                          setFile(
                            $event,
                            'imagenAbajo',
                            alias?.get('idCategoria')?.value,
                            i
                          )
                        "
                      />
                      <mat-form-field fxFlex>
                        <mat-label>URL Imagen Inferior</mat-label>
                        <input
                          #urlInputImagenAbajo
                          matInput
                          formControlName="imagenAbajo"
                        />
                      </mat-form-field>

                      <img
                        [src]="
                          categoriasCliente.controls[i].get('imagenAbajo')
                            ?.value
                        "
                        alt="Sin Imagen"
                        [style.max-width]="'100px'"
                      />
                    </div>
                    <!-- SIRENA -->
                    <div
                      class="mb-3"
                      fxLayout="row"
                      fxLayoutAlign="center center"
                      fxLayoutGap="15px"
                      fxFlex="48"
                    >
                      <button
                        mat-raised-button
                        type="button"
                        class="mb-3"
                        color="primary"
                        (click)="fileInputCategoriaSirena.click()"
                        [disabled]="!nombresValido()"
                      >
                        <mat-icon>upload</mat-icon>
                        <span>Sirena</span>
                      </button>
                      <input
                        #fileInputCategoriaSirena
                        matInput
                        type="file"
                        style="position: fixed; top: -20000px"
                        accept="image/*"
                        (change)="
                          setFile(
                            $event,
                            'imagenSirena',
                            alias?.get('idCategoria')?.value,
                            i
                          )
                        "
                      />
                      <mat-form-field fxFlex>
                        <mat-label>URL Imagen Sirena</mat-label>
                        <input
                          #urlInputImagenSirena
                          matInput
                          formControlName="imagenSirena"
                        />
                      </mat-form-field>

                      <img
                        [src]="
                          categoriasCliente.controls[i].get('imagenSirena')
                            ?.value
                        "
                        alt="Sin Imagen"
                        [style.max-width]="'100px'"
                      />
                    </div>
                    <!-- SIRENA Push -->
                    <div
                      class="mb-3"
                      fxLayout="row"
                      fxLayoutAlign="center center"
                      fxLayoutGap="15px"
                      fxFlex="48"
                    >
                      <button
                        mat-raised-button
                        type="button"
                        class="mb-3"
                        color="primary"
                        (click)="fileInputCategoriaSirenaPush.click()"
                        [disabled]="!nombresValido()"
                      >
                        <mat-icon>upload</mat-icon>
                        <span>Sirena Push</span>
                      </button>
                      <input
                        #fileInputCategoriaSirenaPush
                        matInput
                        type="file"
                        style="position: fixed; top: -20000px"
                        accept="image/*"
                        (change)="
                          setFile(
                            $event,
                            'imagenSirenaPush',
                            alias?.get('idCategoria')?.value,
                            i
                          )
                        "
                      />
                      <mat-form-field fxFlex>
                        <mat-label>URL Imagen Sirena Push</mat-label>
                        <input
                          #urlInputImagenSirenaPush
                          matInput
                          formControlName="imagenSirenaPush"
                        />
                      </mat-form-field>

                      <img
                        [src]="
                          categoriasCliente.controls[i].get('imagenSirenaPush')
                            ?.value
                        "
                        alt="Sin Imagen"
                        [style.max-width]="'100px'"
                      />
                    </div>
                    <!-- Reflector -->
                    <div
                      class="mb-3"
                      fxLayout="row"
                      fxLayoutAlign="center center"
                      fxLayoutGap="15px"
                      fxFlex="48"
                    >
                      <button
                        mat-raised-button
                        type="button"
                        class="mb-3"
                        color="primary"
                        (click)="fileInputCategoriaReflector.click()"
                        [disabled]="!nombresValido()"
                      >
                        <mat-icon>upload</mat-icon>
                        <span>Reflector</span>
                      </button>
                      <input
                        #fileInputCategoriaReflector
                        matInput
                        type="file"
                        style="position: fixed; top: -20000px"
                        accept="image/*"
                        (change)="
                          setFile(
                            $event,
                            'imagenReflector',
                            alias?.get('idCategoria')?.value,
                            i
                          )
                        "
                      />
                      <mat-form-field fxFlex>
                        <mat-label>URL Imagen Reflector</mat-label>
                        <input
                          #urlInputImagenReflector
                          matInput
                          formControlName="imagenReflector"
                        />
                      </mat-form-field>

                      <img
                        [src]="
                          categoriasCliente.controls[i].get('imagenReflector')
                            ?.value
                        "
                        alt="Sin Imagen"
                        [style.max-width]="'100px'"
                      />
                    </div>
                    <!-- Reflector Push -->
                    <div
                      class="mb-3"
                      fxLayout="row"
                      fxLayoutAlign="center center"
                      fxLayoutGap="15px"
                      fxFlex="48"
                    >
                      <button
                        mat-raised-button
                        type="button"
                        class="mb-3"
                        color="primary"
                        (click)="fileInputCategoriaReflectorPush.click()"
                        [disabled]="!nombresValido()"
                      >
                        <mat-icon>upload</mat-icon>
                        <span>Reflector Push</span>
                      </button>
                      <input
                        #fileInputCategoriaReflectorPush
                        matInput
                        type="file"
                        style="position: fixed; top: -20000px"
                        accept="image/*"
                        (change)="
                          setFile(
                            $event,
                            'imagenReflectorPush',
                            alias?.get('idCategoria')?.value,
                            i
                          )
                        "
                      />
                      <mat-form-field fxFlex>
                        <mat-label>URL Imagen Reflector Push</mat-label>
                        <input
                          #urlImagenReflectorPush
                          matInput
                          formControlName="imagenReflectorPush"
                        />
                      </mat-form-field>

                      <img
                        [src]="
                          categoriasCliente.controls[i].get(
                            'imagenReflectorPush'
                          )?.value
                        "
                        alt="Sin Imagen"
                        [style.max-width]="'100px'"
                      />
                    </div>
                    <!-- Adelante -->
                    <div
                      class="mb-3"
                      fxLayout="row"
                      fxLayoutAlign="center center"
                      fxLayoutGap="15px"
                      fxFlex="48"
                    >
                      <button
                        mat-raised-button
                        type="button"
                        class="mb-3"
                        color="primary"
                        (click)="fileInputCategoriaAdelante.click()"
                        [disabled]="!nombresValido()"
                      >
                        <mat-icon>upload</mat-icon>
                        <span>Adelante</span>
                      </button>
                      <input
                        #fileInputCategoriaAdelante
                        matInput
                        type="file"
                        style="position: fixed; top: -20000px"
                        accept="image/*"
                        (change)="
                          setFile(
                            $event,
                            'imagenAdelante',
                            alias?.get('idCategoria')?.value,
                            i
                          )
                        "
                      />
                      <mat-form-field fxFlex>
                        <mat-label>URL Imagen Adelante</mat-label>
                        <input
                          #urlInputImagenAdelante
                          matInput
                          formControlName="imagenAdelante"
                        />
                      </mat-form-field>

                      <img
                        [src]="
                          categoriasCliente.controls[i].get('imagenAdelante')
                            ?.value
                        "
                        alt="Sin Imagen"
                        [style.max-width]="'100px'"
                      />
                    </div>
                    <!-- Adelante Push -->
                    <div
                      class="mb-3"
                      fxLayout="row"
                      fxLayoutAlign="center center"
                      fxLayoutGap="15px"
                      fxFlex="48"
                    >
                      <button
                        mat-raised-button
                        type="button"
                        class="mb-3"
                        color="primary"
                        (click)="fileInputCategoriaAdelantePush.click()"
                        [disabled]="!nombresValido()"
                      >
                        <mat-icon>upload</mat-icon>
                        <span>Adelante Push</span>
                      </button>
                      <input
                        #fileInputCategoriaAdelantePush
                        matInput
                        type="file"
                        style="position: fixed; top: -20000px"
                        accept="image/*"
                        (change)="
                          setFile(
                            $event,
                            'imagenAdelantePush',
                            alias?.get('idCategoria')?.value,
                            i
                          )
                        "
                      />
                      <mat-form-field fxFlex>
                        <mat-label>URL Imagen Adelante Push</mat-label>
                        <input
                          #urlInputImagenAdelantePush
                          matInput
                          formControlName="imagenAdelantePush"
                        />
                      </mat-form-field>

                      <img
                        [src]="
                          categoriasCliente.controls[i].get(
                            'imagenAdelantePush'
                          )?.value
                        "
                        alt="Sin Imagen"
                        [style.max-width]="'100px'"
                      />
                    </div>
                    <!-- Atrás -->
                    <div
                      class="mb-3"
                      fxLayout="row"
                      fxLayoutAlign="center center"
                      fxLayoutGap="15px"
                      fxFlex="48"
                    >
                      <button
                        mat-raised-button
                        type="button"
                        class="mb-3"
                        color="primary"
                        (click)="fileInputCategoriaAtras.click()"
                        [disabled]="!nombresValido()"
                      >
                        <mat-icon>upload</mat-icon>
                        <span>Atras</span>
                      </button>
                      <input
                        #fileInputCategoriaAtras
                        matInput
                        type="file"
                        style="position: fixed; top: -20000px"
                        accept="image/*"
                        (change)="
                          setFile(
                            $event,
                            'imagenAtras',
                            alias?.get('idCategoria')?.value,
                            i
                          )
                        "
                      />
                      <mat-form-field fxFlex>
                        <mat-label>URL Imagen Atrás</mat-label>
                        <input
                          #urlInputImagenAtras
                          matInput
                          formControlName="imagenAtras"
                        />
                      </mat-form-field>

                      <img
                        [src]="
                          categoriasCliente.controls[i].get('imagenAtras')
                            ?.value
                        "
                        alt="Sin Imagen"
                        [style.max-width]="'100px'"
                      />
                    </div>
                    <!-- Atrás Push -->
                    <div
                      class="mb-3"
                      fxLayout="row"
                      fxLayoutAlign="center center"
                      fxLayoutGap="15px"
                      fxFlex="48"
                    >
                      <button
                        mat-raised-button
                        type="button"
                        class="mb-3"
                        color="primary"
                        (click)="fileInputCategoriaAtrasPush.click()"
                        [disabled]="!nombresValido()"
                      >
                        <mat-icon>upload</mat-icon>
                        <span>Atras Push</span>
                      </button>
                      <input
                        #fileInputCategoriaAtrasPush
                        matInput
                        type="file"
                        style="position: fixed; top: -20000px"
                        accept="image/*"
                        (change)="
                          setFile(
                            $event,
                            'imagenAtrasPush',
                            alias?.get('idCategoria')?.value,
                            i
                          )
                        "
                      />
                      <mat-form-field fxFlex>
                        <mat-label>URL Imagen Atrás Push</mat-label>
                        <input
                          #urlInputImagenAtrasPush
                          matInput
                          formControlName="imagenAtrasPush"
                        />
                      </mat-form-field>

                      <img
                        [src]="
                          categoriasCliente.controls[i].get('imagenAtrasPush')
                            ?.value
                        "
                        alt="Sin Imagen"
                        [style.max-width]="'100px'"
                      />
                    </div>
                  </div>
                </mat-expansion-panel>
              </div>
              <button
                *ngIf="i !== 0"
                mat-raised-button
                color="warn"
                (click)="deleteCategoriasCliente(i)"
                type="button"
                style="margin-top: 1em"
              >
                Eliminar
              </button>
            </div>

            <button
              mat-raised-button
              color="primary"
              (click)="addCategoriasCliente()"
              type="button"
            >
              + Agregar Categoría
            </button>
          </div>

          <!-- Categorias Por Default -->
          <div style="margin-bottom: 2em">
            <h2>Categoría Por Defecto</h2>
            <mat-form-field>
              <mat-label>Categoría por defecto</mat-label>
              <mat-select formControlName="idCategoriaDefault" required>
                <ng-container *ngFor="let dato of categorias">
                  <mat-option
                    *ngIf="mostrarCategoriaDefault(dato)"
                    [value]="dato._id"
                  >
                    {{ dato.nombre }}
                    {{ dato.variante ? "(" + dato.variante + ")" : "" }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Categorias Por Edad -->

          <div formArrayName="categoriasDefault">
            <h2>Categorías Por Edad</h2>
            <div
              class="subgrupo"
              *ngFor="let alias of categoriasDefault?.controls; let i = index"
            >
              <div [formGroupName]="i" fxLayout="row">
                <div
                  fxLayout="row"
                  fxLayoutAlign="space-between end"
                  fxLayoutGap="10px"
                  fxFlex="90"
                >
                  <mat-form-field>
                    <mat-label>Edad mayor o igual a</mat-label>
                    <input matInput type="number" formControlName="desde" />
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>Edad menor o igual a</mat-label>
                    <input matInput type="number" formControlName="hasta" />
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>Categoría </mat-label>
                    <mat-select formControlName="idCategoria" required>
                      <ng-container *ngFor="let dato of categorias">
                        <mat-option
                          *ngIf="mostrarCategoriaDefault(dato)"
                          [value]="dato._id"
                        >
                          {{ dato.nombre }}
                          {{ dato.variante ? "(" + dato.variante + ")" : "" }}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div fxFlex="10" fxLayoutAlign="center">
                  <button
                    mat-mini-fab
                    color="warn"
                    matTooltip="Eliminar"
                    (click)="deleteCategoriasDefaultCliente(i)"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </div>

            <button
              mat-raised-button
              color="primary"
              (click)="addCategoriasDefaultCliente()"
              type="button"
            >
              + Agregar Categoría Por Edad
            </button>
          </div>
        </ng-container>
      </mat-expansion-panel>

      <!-- Mapa -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Mapa </mat-panel-title>
          <mat-panel-description>
            Mapa para marcar el territorio del cliente
          </mat-panel-description>
        </mat-expansion-panel-header>

        <!-- Contenido -->
        <ng-container>
          <!-- Mapa -->
          <div class="mt-2 mb-2">
            <app-polygon-draw
              [coordenadas]="data?.coordenadas || []"
              [otrosPoligonos]="otrosClientes"
              [height]="'60vh'"
              [zoom]="9"
            ></app-polygon-draw>
          </div>
        </ng-container>
      </mat-expansion-panel>

      <!-- Monitoreo -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Monitoreo </mat-panel-title>
          <mat-panel-description>
            <span>Configuraciones para la APP de Monitoreo</span>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <!-- Contenido -->
        <ng-container>
          <!-- Nombre App monitoreo -->
          <mat-form-field [formGroup]="configuracion">
            <mat-label>Nombre de la App de Monitoreo</mat-label>
            <input
              matInput
              placeholder="Centro de Monitoreo de Watson"
              formControlName="nombreAppMonit"
            />
          </mat-form-field>

          <!-- Ver Actualizaciones -->
          <div class="mb-3" [formGroup]="configuracion">
            <mat-checkbox
              formControlName="verActualizaciones"
              style="margin-bottom: 1em"
            >
              Ver Actualizaciones de Firmware
            </mat-checkbox>
          </div>

          <!-- Offline como Online -->
          <div class="mb-3" [formGroup]="configuracion">
            <mat-checkbox formControlName="verSirenasOnline"
              >Ver Sirenas Offline como Online</mat-checkbox
            >
          </div>
        </ng-container>
      </mat-expansion-panel>

      <!-- Boton -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Botón </mat-panel-title>
          <mat-panel-description>
            <span>Configuraciones para la APP del Botón</span>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <!-- Contenido -->
        <ng-container>
          <div class="mb-3">
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="1em"
              [formGroup]="configuracion"
            >
              <mat-form-field style="width: 100%">
                <mat-label>Color del Menú</mat-label>
                <input matInput readonly formControlName="colorMenu" />
                <ngx-colors
                  matSuffix
                  ngx-colors-trigger
                  formControlName="colorMenu"
                  (change)="onColorChange($event, 'colorMenu')"
                >
                </ngx-colors>
              </mat-form-field>

              <mat-form-field style="width: 100%">
                <mat-label>Color del Menú Inferior</mat-label>
                <input matInput readonly formControlName="colorMenuInferior" />
                <ngx-colors
                  class="suffix"
                  matSuffix
                  ngx-colors-trigger
                  formControlName="colorMenuInferior"
                  (change)="onColorChange($event, 'colorMenuInferior')"
                >
                </ngx-colors>
              </mat-form-field>

              <mat-form-field style="width: 100%">
                <mat-label>Color del Botón Instalar</mat-label>
                <input matInput readonly formControlName="colorInstalar" />
                <ngx-colors
                  class="suffix"
                  matSuffix
                  ngx-colors-trigger
                  formControlName="colorInstalar"
                  (change)="onColorChange($event, 'colorInstalar')"
                >
                </ngx-colors>
              </mat-form-field>
            </div>
          </div>

          <div class="mb-3" [formGroup]="configuracion">
            <div
              fxLayout="row"
              fxLayoutAlign="space-between center"
              fxLayoutGap="1em"
            >
              <!-- Boton Mobile 1  -->

              <mat-form-field>
                <mat-label>Botón Mobile 1</mat-label>
                <mat-select formControlName="boton1Mobile">
                  <mat-option *ngFor="let c of botonesMobile" [value]="c">
                    {{ c }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- Boton Mobile 2  -->
              <mat-form-field>
                <mat-label>Botón Mobile 2</mat-label>
                <mat-select formControlName="boton2Mobile">
                  <mat-option *ngFor="let c of botonesMobile" [value]="c">
                    {{ c }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- Boton Mobile 2  -->
              <mat-form-field>
                <mat-label>Botón Mobile 3</mat-label>
                <mat-select formControlName="boton3Mobile">
                  <mat-option *ngFor="let c of botonesMobile" [value]="c">
                    {{ c }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <!-- Contactos  -->
          <div class="mb-3" [formGroup]="configuracion">
            <mat-checkbox formControlName="verContactos">
              Mostrar Contactos
            </mat-checkbox>
          </div>

          <!-- Mostrar Reclamos  -->
          <div class="mb-3" [formGroup]="configuracion">
            <mat-checkbox formControlName="verReclamos">
              Mostrar Reclamos
            </mat-checkbox>
          </div>

          <!-- Mostrar Eventos  -->
          <div class="mb-3" [formGroup]="configuracion">
            <mat-checkbox formControlName="verEventos">
              Mostrar Eventos
            </mat-checkbox>
          </div>

          <!-- Mostrar Turnos  -->
          <div class="mb-3" [formGroup]="configuracion">
            <mat-checkbox formControlName="verTurnos">
              Mostrar Turnos
            </mat-checkbox>
          </div>

          <!-- Mostrar Estacionamiento Medido  -->
          <div class="mb-3" [formGroup]="configuracion">
            <mat-checkbox formControlName="verEstacionamientoMedido">
              Mostrar Estacionamiento Medido
            </mat-checkbox>
          </div>

          <!-- Mostrar Colectivos  -->
          <div class="mb-3" [formGroup]="configuracion">
            <mat-checkbox formControlName="verColectivos">
              Mostrar Colectivos
            </mat-checkbox>
          </div>

          <!-- Mostrar Mapa -->
          <div class="mb-3" [formGroup]="configuracion">
            <mat-checkbox formControlName="mostrarMapa">
              Mostrar Mapa
            </mat-checkbox>
          </div>

          <!-- Mostrar Sirenas Offline -->
          <div class="mb-3" [formGroup]="configuracion">
            <mat-checkbox formControlName="mostrarSirenasOffline">
              Mostrar Sirenas Offline
              <small
                >- Si no está activado no se muestra ningún icono en el mapa
                (como si la sirena no existiera)</small
              >
            </mat-checkbox>
          </div>

          <!-- Mostrar Estado Sirenas -->
          <div class="mb-3" [formGroup]="configuracion">
            <mat-checkbox formControlName="mostrarEstadoSirenas">
              Mostrar Estado Sirenas
              <small
                >- Muestra de distintos colores si las sirenas estan online u
                offline (si no se activa se ven todas azules)</small
              >
            </mat-checkbox>
          </div>

          <!-- Notificacion uso de sirenas -->
          <div class="mb-3" [formGroup]="configuracion">
            <mat-checkbox formControlName="notificacionesSirenas">
              Notificacion de uso de sirenas
              <small
                >- Envia a los vecinos con domicilio cercano a la sirena una
                notificacion cuando se detona</small
              >
            </mat-checkbox>
          </div>
        </ng-container>
      </mat-expansion-panel>

      <!-- Controles RF -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Controles RF </mat-panel-title>
          <mat-panel-description>
            Configuraciones para los controles RF del cliente
          </mat-panel-description>
        </mat-expansion-panel-header>
        <!-- Contenido -->
        <ng-container [formGroup]="configuracion">
          <!-- Botón 1 -->
          <div class="row-center" style="gap: 8px">
            <section class="layout">
              <div>
                <span> Botón 1 (A): </span>
              </div>
              <div class="grow1">
                <mat-form-field>
                  <mat-label>Acción </mat-label>
                  <mat-select
                    [value]="boton1"
                    (selectionChange)="updateBoton(1, $event.value)"
                  >
                    @for (a of acciones; track a) {
                      <mat-option [value]="a">{{ a }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
              @if (boton1 === "Alerta") {
                <div class="grow1">
                  <ng-select
                    placeholder="Alerta"
                    formControlName="boton1"
                    appendTo="body"
                  >
                    @for (b of botones; track b._id) {
                      <ng-option [value]="b._id">{{ b.nombre }}</ng-option>
                    }
                  </ng-select>
                </div>
              }
            </section>
          </div>
          <!-- Botón 2 -->
          <div class="row-center" style="gap: 8px">
            <section class="layout">
              <div><span>Botón 2 (B):</span></div>
              <div class="grow1">
                <mat-form-field>
                  <mat-label>Acción </mat-label>
                  <mat-select
                    [value]="boton2"
                    (selectionChange)="updateBoton(2, $event.value)"
                  >
                    @for (a of acciones; track a) {
                      <mat-option [value]="a">{{ a }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
              @if (boton2 === "Alerta") {
                <div class="grow1">
                  <ng-select
                    placeholder="Alerta"
                    formControlName="boton2"
                    appendTo="body"
                  >
                    @for (b of botones; track b._id) {
                      <ng-option [value]="b._id">{{ b.nombre }}</ng-option>
                    }
                  </ng-select>
                </div>
              }
            </section>
          </div>
          <!-- Botón 3 -->
          <div class="row-center" style="gap: 8px">
            <section class="layout">
              <div><span>Botón 3 (C):</span></div>
              <div class="grow1">
                <mat-form-field>
                  <mat-label>Acción </mat-label>
                  <mat-select
                    [value]="boton3"
                    (selectionChange)="updateBoton(3, $event.value)"
                  >
                    @for (a of acciones; track a) {
                      <mat-option [value]="a">{{ a }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
              @if (boton3 === "Alerta") {
                <div class="grow1">
                  <ng-select
                    placeholder="Alerta"
                    formControlName="boton3"
                    appendTo="body"
                  >
                    @for (b of botones; track b._id) {
                      <ng-option [value]="b._id">{{ b.nombre }}</ng-option>
                    }
                  </ng-select>
                </div>
              }
            </section>
          </div>
        </ng-container>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Eventos Externos </mat-panel-title>
          <mat-panel-description>
            Configuraciones para los eventos externos del cliente
          </mat-panel-description>
        </mat-expansion-panel-header>
        <!-- Contenido -->
        <!-- Activar sirena -->
        <ng-container [formGroup]="configuracion">
          <mat-checkbox formControlName="activarSirena">
            Activar sirena cercana
          </mat-checkbox>
          <div class="row-center" style="gap: 8px">
            <section class="layout">
              <div class="grow1">
                <ng-select
                  placeholder="Alerta"
                  formControlName="alertaEventoExterno"
                  appendTo="body"
                >
                  @for (b of botones; track b._id) {
                    <ng-option [value]="b._id">{{ b.nombre }}</ng-option>
                  }
                </ng-select>
              </div>
            </section>
          </div>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</form>

<!-- Botones -->
<div
  fxLayoutGap="10px"
  style="text-align: end; position: sticky; bottom: 0; background: white"
  class="mat-elevation-z8 p-2"
>
  <button
    class="botonSpinner"
    mat-raised-button
    color="primary"
    type="submit"
    [disabled]="!formulario?.valid || loading"
    form="form"
  >
    <div class="loadSpinnerButton" [style.display]="loading ? 'flex' : 'none'">
      <mat-spinner
        class="mx-2"
        [diameter]="25"
        mode="indeterminate"
        color="warn"
      ></mat-spinner>
    </div>
    <span [style.display]="loading ? 'none' : 'block'">Guardar</span>
  </button>

  <button mat-raised-button type="button" (click)="volver()">Volver</button>
</div>
